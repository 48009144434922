<template>
  <el-table
      :data="tableData"
      :header-cell-style="{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }"
      style="width: 100%">
    <el-table-column
        prop="order"
        label="序号"
        align="center">
    </el-table-column>
    <el-table-column
        prop="name"
        label="专辑封面">
      <template slot-scope="scope">
        <img  style="width: 56px;height: 56px;" :src="scope.row.cover" alt="" />
      </template>
    </el-table-column>
    <el-table-column
        prop="name"
        v-slot="{row}"
        label="专辑名称">
      <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :title="row.name">
        {{ row.name }}
      </div>
    </el-table-column>
    <el-table-column
        prop="songNum"
        label="歌曲数">
    </el-table-column>
    <el-table-column
        label="首发时间"
        v-slot="{row}"
    >
      {{$timestampToDate(row.submitTime)}}
    </el-table-column>
    <el-table-column
        :formatter="warrantType"
        label="授权状态">
    </el-table-column>
    <el-table-column
     :formatter="issueType"
    >
     <template slot="header">
       <el-dropdown @command="commandFn">
        <span style="color: #333333; font-weight: 400; font-size: 14px" >
               发行状态<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
         <el-dropdown-menu slot="dropdown">
           <el-dropdown-item  v-for="item in options" :key="item.value" :command="item.value" >{{item.label}}</el-dropdown-item>
         </el-dropdown-menu>
       </el-dropdown>
     </template>
    </el-table-column>
    <el-table-column
        prop="address"
        label="操作"
        v-slot="{row}">
      <el-link v-show="row.status !== 2" type="primary" @click="$router.push('/album/' + row.id)" style="margin-right: 10px">详情</el-link>
      <el-link v-show="row.status === 2" type="primary" @click="$router.push('/release/' + row.id)" style="margin-right: 10px">修改</el-link>
      <el-link v-show="row.status === 2" type="primary">
        <el-popover
            placement="top"
            width="160"
            :ref="'popover-' + row.id">
          <p>删除后不可撤回，请确认是否删除？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="$refs['popover-' + row.id].showPopper = false">取消</el-button>
            <el-button type="primary" size="mini" @click="deleteAlbum(row.id)">确定</el-button>
          </div>
          <el-link type="primary" slot="reference">删除</el-link>
        </el-popover>
      </el-link>
    </el-table-column>
  </el-table>
</template>

<script>
import {delAlbum} from "@/api/song"

export default {
  props:{
    albumList: Array,
    issueStatusFn:Function,
  },
  name: "albumList",
  data(){
    return {
      tableData: [],
      options:[{label:'全部',value:'0,1'},{label:'已发行',value: '1'},{label:'未发行',value: '0'}]
    }
  },
  methods:{
    deleteAlbum(id){
      delAlbum({}, {albumId: id}).then(() => {
        this.$parent.albumListFn()
        this.$refs['popover-' + id].showPopper = false
      })
    },
    warrantType(val){
      let title = ''
      switch (val.status){
       case 0:
         title = '授权审核中'
         break;
        case 2:
          title = '授权失败'
          break;
        case 1:
          title = '授权成功'
          break;
      }
      return title
    },
    commandFn(command){
      this.issueStatusFn(command)
    },
  // 发行状态筛选
    issueType(val){
      if(val.issueStatus === 0){
        return '未发行'
      }else {
        return '已发行'
      }
    }
  },
  watch:{
    albumList(val){
    this.tableData = val
    }
  }
}
</script>

<style scoped lang="scss">
</style>
