import { render, staticRenderFns } from "./albumList.vue?vue&type=template&id=058f61bc&scoped=true&"
import script from "./albumList.vue?vue&type=script&lang=js&"
export * from "./albumList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058f61bc",
  null
  
)

export default component.exports