<template>
  <el-table
      :data="tableData"
      :header-cell-style="{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }"
      style="width: 100%">
    <el-table-column
        prop="order"
        width="76px"
        label="序号"
        align="center">
    </el-table-column>
    <el-table-column
        prop="name"
        v-slot="{row}"
        label="歌曲名">
      <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" :title="row.name">
        {{ row.name }}
      </div>
    </el-table-column>
    <el-table-column
        prop="albumName"
        v-slot="{row}"
        label="所属专辑">
      <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" :title="row.albumName">
        {{ row.albumName }}
      </div>
    </el-table-column>
    <el-table-column
        label="提交时间"
        width="200px"
         v-slot="{row}"
    >
      {{$timestampToString(row.submitTime)}}
    </el-table-column>
    <el-table-column
        :formatter="warrantType"
        label="授权状态">
    </el-table-column>
    <el-table-column
     :formatter="function (
         val
     ){
       return val.issueStatus ? '已发行' : '未发行'
     }"
    >
      <template slot="header">
        <el-dropdown @command="commandHeaderFn">
      <span class="el-dropdown-link">
        发行状态<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in options" :command="item.value" :key="item.value">{{item.label}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
<!--    <el-table-column-->
<!--        prop="address"-->
<!--        label="上架平台">-->
<!--      <template>-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column
        prop="address"
        v-slot="{row}"
        label="操作">
      <template>
        <el-link type="primary" @click="$router.push('/song/' + row.id)">详情</el-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "songList",
  props:{
    songList:Array,
    issueStatusFn:Function
  },
  data(){
    return {
      tableData: [],
      options:[{label:'全部',value:'0,1'},{label:'已发行',value:"1"},{label:'未发行',value:'0'}]
    }
  },
  methods:{
    warrantType(val){
      let title =''
      switch (val.status) {
        case 0:
        title = "授权审核中";
          break
        case 1:
          title = "已授权";
          break
        case 2:
          title = "授权未通过";
          break
      }
      return title
    },
    commandHeaderFn(command){
      this.issueStatusFn(command)
    },
  },
  watch:{
    songList(val){
    this.tableData = val
    }
  }
}
</script>

<style scoped lang="scss">
</style>
