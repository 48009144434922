var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-cell-style":{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }}},[_c('el-table-column',{attrs:{"prop":"order","width":"76px","label":"序号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"name","label":"歌曲名"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"title":row.name}},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"albumName","label":"所属专辑"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},attrs:{"title":row.albumName}},[_vm._v(" "+_vm._s(row.albumName)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"提交时间","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm.$timestampToString(row.submitTime))+" ")]}}])}),_c('el-table-column',{attrs:{"formatter":_vm.warrantType,"label":"授权状态"}}),_c('el-table-column',{attrs:{"formatter":function (
         val
     ){
       return val.issueStatus ? '已发行' : '未发行'
     }}},[_c('template',{slot:"header"},[_c('el-dropdown',{on:{"command":_vm.commandHeaderFn}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 发行状态"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.options),function(item){return _c('el-dropdown-item',{key:item.value,attrs:{"command":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1)],2),_c('el-table-column',{attrs:{"prop":"address","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [[_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push('/song/' + row.id)}}},[_vm._v("详情")])]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }