<template>
  <div class="manage">
    <div class="header" v-if="albumNumFormDraft > 0">
      <div class="l"><img alt="加载失败" src="../../assets/songManage.png">您有{{albumNumFormDraft}}个草稿待发布</div>
      <div class="r" @click="$router.push('/song-draft')">查看</div>
    </div>
    <div class="content">
      <div class="empower">
        <div class="isEmpower">
          <div @click="isWarrant = 1" :class="{active:isWarrant === 1}">已授权</div>
          <div  @click="isWarrant = 0"  :class="{active:isWarrant === 0}">未授权</div>
        </div>
        <div class="issue" @click="$router.push('/release/0')">发布歌曲</div>
      </div>
      <div class="songAndAlbum">
        <ul class="radio">
          <li :class="{active:isSong === 0}" @click="isSong = 0">
            专辑 {{albumTotal === 0 ? '':albumTotal}}
            <div></div>
          </li>
          <li @click="isSong = 1" :class="{active:isSong === 1}">
            歌曲 {{songTotal === 0 ? "":songTotal}}
            <div></div>
          </li>
        </ul>
        <div class="search">
          <el-input prefix-icon="el-icon-search" v-model="name" placeholder="专辑/歌曲名称" @input="lookupFn">
          </el-input>
        </div>
      </div>
      <album-list :issueStatusFn="issueStatusFn" :albumList="songList" v-if="isSong === 0" ></album-list>
      <song-list :issueStatusFn="issueStatusFn"  :songList="songList" v-if="isSong === 1"  ></song-list>
      <div class="footer-pagination">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            @current-change="currentChange"
            background
            layout="prev, pager, next, slot"
            hide-on-single-page
            :total="paging.total">
          <ul class="alone">
            <li class="totalPage">
              共 {{paging.pages}} 页
            </li>
            <li>到第
              <el-input v-model="inputVal"></el-input>
            </li>
            <li>
              <el-button @click="butfn()">确定</el-button>
            </li>
          </ul>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {albumList,musicianSongList} from '../../api/song'
import AlbumList from "@/views/songManage/components/albumList";
import SongList from "@/views/songManage/components/songList";
let timer ;
export default {
  name: "songManage",
  components: {SongList, AlbumList},
  data(){
    /**
     * inputVal 分页器输入框
     * isWarrant 是否授权
     * isSong  歌曲或者专辑
     * currentPage 当前页面
     * pageSize 每页条数
     * dialogVisible 弹框显示隐藏
     * name 歌曲名字/专辑名字
     *  issueStatus    发行状态
     * paging 数据条数，页数
     * indexobj 首页数据
     *   albumTotal专辑条数
     *   songtTotal 歌曲条数
     * */
    return {
      isWarrant:0,
      isSong:0,
      inputVal:1,
      currentPage:1,
      pageSize:10,
      issueStatus:'0,1',
      dialogVisible:true,
      name:'',
      songList:[],
      paging:{},
      albumTotal:0,
      songTotal:0,
      albumNumFormDraft:0
    }
  },
  created() {
    this.albumListFn()
    this.songIndexFn()
    this.songAndAlbum()
  },
  methods:{
    butfn(){
      this.currentPage = Number(this.inputVal)
      if(this.isSong){
        this.songListFn()
      }else {
        this.albumListFn()
      }
    },
    currentChange(val){
      this.currentPage = Number(val);
      this.inputVal = val;
      if(this.isSong){
        this.songListFn()
      }else {
        this.albumListFn()
      }
    },
  //  专辑列表
    albumListFn(){
      let p = {
        status:this.isWarrant === 0 ?'0,2' : '1',
        pageNum:this.currentPage,
        pageSize:this.pageSize,
        name:this.name,
        issueStatus:this.issueStatus
      }
      albumList(p).then(res=>{
       if(res.code === 0){
         let { list, pageNum,total,pages } = res.data
            list.forEach((item, index) =>{
              item.order = Number(pageNum - 1) * this.pageSize + (index + 1)
            })
         this.songList = list
         this.paging = {
           total,
           pages
         }

       }
      })
    },
  //  修改发行状态
    issueStatusFn(val){
      this.issueStatus = val
      this.InitialWords()
    },
  //  歌曲列表
    songListFn(){
      let p ={
        status:this.isWarrant === 0 ?'0,2' : '1',
        pageNum:this.currentPage,
        pageSize:this.pageSize,
        issueStatus:this.issueStatus,
        name: this.name
      }
      musicianSongList(p).then(res=>{
        if(res.code === 0){
          let { list, pageNum,total,pages } = res.data
          list.forEach((item, index) =>{
            item.order = Number(pageNum - 1) * this.pageSize + (index + 1)
          })
          this.songList = list
          this.paging = {
            total,
            pages
          }
        }

      })
    },
  // 查找
    lookupFn(){
      clearInterval(timer)
      if (this.isSong) {
          timer = setTimeout(()=>{
            this.InitialWords()
            this.songAndAlbum()
          },800)
      }else {
        timer = setTimeout(()=>{
          this.InitialWords()
          this.songAndAlbum()
        },800)
      }
    },
    //需要初始化分页器时调用
    InitialWords(){
      this.currentPage = 1
      this.inputVal = 1;
      if(this.isSong){
        this.songListFn()
      }else {
        this.albumListFn()
      }
    },
    //  首页数据
    songIndexFn(){
      albumList({
        pageSize:1,
        pageNum:1,
        status:4
      }).then(res=>{
        if(res.code === 0){
            this.albumNumFormDraft = res.data.total
        }
      })
    },
  // 歌曲专辑数据
    songAndAlbum(){
      musicianSongList({
        pageSize:1,
        pageNum:1,
         name:this.name,
        status:this.isWarrant === 0 ?'0,2' : '1',
      }).then(res=>{
        if(res.code === 0){
          this.songTotal = res.data.total
        }
      })
      albumList({
        pageSize:1,
        pageNum:1,
        name:this.name,
        status:this.isWarrant === 0 ?'0,2' : '1',
      }).then(res=>{
         if(res.code === 0){
           this.albumTotal = res.data.total
         }
      })
    }
  },
  watch:{
    isWarrant(){
      this.name = ''
      this.songAndAlbum()
      if(this.isSong){
        this.currentPage = 1
        this.inputVal = 1;
        this.songListFn()
      }else {
        this.currentPage = 1
        this.inputVal = 1;
        this.albumListFn()
      }
    },
    isSong(val){
      if(val){
        this.currentPage = 1
        this.inputVal = 1;
        this.songListFn()
      }else {
        this.currentPage = 1
        this.inputVal = 1;
        this.albumListFn()
      }
    }
  }
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.manage {
  .header {
    height: 50px;
    background: #ffeecd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .l {
      color: #775834;

      img {
        vertical-align: middle;
        margin-right: 12px;
        width: 22px;
        height: 22px;
      }
    }

    .r {
      width: 80px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      line-height: 28px;
      text-align: center;
      color: #333333;
      cursor: pointer;
    }
  }
  .content {
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100%;
    min-height: 700px;
    padding: 30px 40px;

    .empower {
      display: flex;
      justify-content: space-between;

      .isEmpower {
        width: 203px;
        height: 43px;
        line-height: 43px;
        background: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #F4F4F4;
        font-size: 15px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        display: flex;

        div {
          flex: 1;
          text-align: center;
          cursor: pointer;
        }

        .active {
          background-color: #ffffff;
          font-weight: 500;
          color: #FF004D;
          font-family: PingFang SC-Medium;
          cursor: default;
        }
      }

      .issue {
        width: 116px;
        height: 40px;
        background: #ff004d;
        border-radius: 2px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }

    .songAndAlbum {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .radio {
        font-size: 15px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        display: flex;
        li {
          margin-right: 44px;
          cursor: pointer;
          text-align: center;
          display: flex;
          flex-direction: column;
           align-items: center;
          div {
            width: 44px;
            height: 2px;
            background-color: #FFFFFF;
            margin-top:14px;
          }
        }

        .active {
          cursor: default;
          color: #ff004d;
          div {
           background-color: #ff004d;
          }
        }
      }
      .search{
        ::v-deep .el-input__inner{
          width: 275px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 3px;
        }
      }

    }
    .footer-pagination {
      margin: 39px 0 39px 0;
      display: flex;
      justify-content: center;

      .alone {
        display: inline-flex;
        height: 30px;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #555555;

        .totalPage {
          width: 40px;
          margin: 0 17px 0 6px;
        }

        ::v-deep .el-input {
          .el-input__inner {
            width: 30px;
            height: 30px;
            border: 1px solid #e1e0e0;
            border-radius: 2px;
          }
        }
        .el-button{
          margin-left:6px;
          width: 48px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e0e0;
          border-radius: 2px;
        }
      }

      .alone > li {
        display: inline-block;
      }
    }
  }
}
</style>
